td{
    vertical-align: middle;
}

.cart .sppgbtm-fixxdv {padding: 20px;left: 0px;bottom: 0px;border-top: 4px solid #335e90;background-color: white;margin: 0px;overflow: hidden;}

.cart .sppgbtm-fixxdv:after {top: 12px;background: #335e90;padding: 5px 40px;color: #fff;border-radius: 0px 0px 10px 10px;font-weight: 700;}

.txt-cnt{
    /* display: inline-flex; */
    margin: 10px;
    
}

.txt-cnt p{
   float: right;
   font-weight: 800;
}
.txt-cnt h4{
    float: left;
 }

 .right_btn{
    float: right;
    padding-top: 50px;
    display: grid;
 }
 .right_btn .btn1{
    width: 100%;
    border: 0px solid transparent;
    color: #335e90;
    padding: 5px 30px;
    background-color: white;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 10px;
    border: 2px solid #335e90;
 }
 .right_btn .btn2{
    width: 100%;
    border: 0px solid transparent;
    /* color:black; */
    padding: 5px 30px;
    background-color:#d3d3d370;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 10px;
    text-transform: uppercase;
 }

 

 .left_btn{
    padding-top: 50px;
    float: left;
    display: grid;
 }

 .left_btn .btn1{
    width: 100%;
    border: 0px solid transparent;
    color: #fff;
    padding: 30px 40px;
    background-color: #335e90;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px;
 }
 
 .form-control {
    padding: 3% !important;
    background-color: #f4f5f7 !important;
    border: 0px !important;
    transition: all 0.4s !important;
    margin-top: 40px !important;
    font-size: 25px !important;
    font-weight: 700 !important;
  }

  @media only screen and (max-width: 600px) {
    .right_btn{
        float: none;
        padding-top: 10px;
     }

     .left_btn{
        padding-top: 10px;
        float: none;
       
     }
     td{
        font-size: 12px !important;
     }
     .stepper-input {
       
        width: 100px !important;
        
      }
  }