.App {
    font-family: sans-serif;
    text-align: center;
    padding: 15px;
    background: white;
  }
  

  .DatePicker_container__psEYm{
    border-top: 1px solid gray;
padding: 35px;
border-bottom: 1px solid gray;
  }
  .DatePicker_monthYearLabel__JFisN{
    display: none;
  }

  #selected{
        width: 60px !important;
        height: 60px !important;
        border-radius: 30% !important;
        border: none !important;
  }
  /* #selected .DatePicker_dateLabel__i8uZQ{
        border-radius: 10% !important;
        background:  #335e90;
        color: white;
        width: 46%;
        text-align: center;

  } */
/* .DatePicker_buttonWrapper__mC8SX button:after{
    content: " > ";
} */
  
  .DatePicker_container__psEYm{
    padding: 0px !important;
  }
  .DatePicker_buttonWrapper__mC8SX{
    padding: 15px !important;
  }
  .DatePicker_daysContainer__\+hl4N{
    padding: 10px !important;
  }
  .DatePicker_dayLabel__XLZhF{
    font-size: 15px !important;
  }
  .DatePicker_dateLabel__i8uZQ{
    font-size: 20px !important;
  }

  .DatePicker_dateDayItem__tdtdf{
    width: 60px !important;
    height: 60px !important;
  }
  
  .main-morning{
    box-shadow: 1px 2px 9px #2D2D2B4F;
    border-radius: 10px;
    display: inline-flex;
    width: 100%;
    height: 60px;
    margin-top: 30px;
  }

  .morning-image{
    padding-left: 20px;
    padding-right: 10px;
    align-self: center;
  }
  .morning-image img{
    height: 30px;
   
  }
  .morning p{
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 5px !important;
    padding-left: 10px;
    margin-top: 5px !important;
  }

  .main-afternoon{
    box-shadow: 1px 2px 9px #2D2D2B4F;
    border-radius: 10px;
    display: inline-flex;
    width: 100%;
    height: 60px;
    margin-top: 30px;
  }

  .afternoon-image{
    padding-left: 20px;
    padding-right: 10px;
    align-self: center;
  }
  .afternoon-image img{
    height: 30px;
   
  }
  .afternoon p{
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 5px !important;
    padding-left: 10px;
    margin-top: 5px !important;
  }
  .appointment{
    font-weight: initial !important;
  }
  .main-time{
    box-shadow: 1px 2px 9px #2D2D2B4F;
        border-radius: 10px;
        margin-top: 15px;
        width: 100%;
        font-size: 18px;
        text-align: center;
        color: #335e90;
        font-weight: 500;
  }
  
  .main-time:hover{
    font-weight: 500;
    color: #fff;
    background-color: #335e90;
  }
 
  .morning_set_active{
    color: white;
    background-color: #335e90;
  }

  .afternoon_set_active{
    color: white;
    background-color: #335e90;
  }